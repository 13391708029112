import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import PlainPage from 'components/PlainPage';

export default props => {
    return (
        <Layout>
            <PlainPage
                content={props.data.contentfulUtilityJsonModel.content.html}
                fullWidth
            />
        </Layout>
    );
};

export const query = graphql`
    query($contentful_id: String!, $locale: String) {
        contentfulUtilityJsonModel(
            contentful_id: { eq: $contentful_id }
            node_locale: { eq: $locale }
        ) {
            content {
                title
                html
            }
        }
    }
`;
